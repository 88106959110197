import React from "react";
import { graphql } from "gatsby";
import { BlogPostJsonLd, GatsbySeo } from "gatsby-plugin-next-seo";
import Img from "gatsby-image";
import  axios from "axios"


function AbruzzoNews({pageContext, context}) {
    


const [card, setCard] = React.useState([])

React.useEffect(()=> {

  async  function fetchData() {
    let {data} = await axios.get(`https://api.spoonacular.com/recipes/${pageContext.id}/card?apiKey=${process.env.GATSBY_FOOD_API}`)
    await setCard(data.url)
  }

  fetchData()

},[])

  return (
    <>
          <GatsbySeo
        title={`${pageContext.title} | Casa Fraine`}
        description={pageContext.content}
        canonical={`https://www.casafraine.com/${pageContext.title.replace(/\s+/g, "-")}`}
        openGraph={{
          url: `https://www.casafraine.com/${pageContext.title.replace(/\s+/g, "-")}`,
          title: `${pageContext.title}`,
          description: `${pageContext.content}`,  
          site_name: "Casa Fraine",
          type: 'article',
          article: {
            publishedTime: '2022-06-21T23:04:13Z',
            modifiedTime: '2022-01-21T18:04:43Z',
            expirationTime: '2025-12-21T22:04:11Z',
            section: 'Recipes',
            authors: [
              'https://www.vincenzo.codes/about'
                        ],
            tags: ['Ricette', 'Ricette Abruzzesi', 'Cucina','cooking','homemade food'],
          },
          images: [
            {
              url: `${pageContext.image}`,
              width: 850,
              height: 650,
              alt: `${pageContext.title}`,
            },
          ],
        }}
        twitter={{
          handle: "Vincenzo Marcovecchio",
          site: "www.casafraine.com",
          cardType: "summary_large_image",
        }}
      />

      <BlogPostJsonLd
        url={`https://www.casafraine.com/${pageContext.title.replace(/\s+/g, "-")}`}
        title={`${pageContext.title} | Casa Fraine`}
        authorName="Fronte popolare contadino"
        description={pageContext.content}
        images={[
          `${pageContext.image}`,
          `${pageContext.image}`,
          `${pageContext.image}`,
        ]}
        datePublished='2015-02-05T08:00:00+08:00'
        dateModified='2015-02-05T09:00:00+08:00'
      />
    
      <h1 style={{marginTop:'1rem', marginBottom:'1rem'}} className="article">{pageContext.title}</h1>

      <article className="article booking" >
      <img
            className="img__article"
            style={{maxHeight:'25rem'}}
            src={pageContext.image}
            alt={pageContext.title}
          />
        <div  style={{margin:'2rem auto'}} dangerouslySetInnerHTML={{__html: pageContext.content}}></div>
      </article>
    
{card.length > 0 ? <img src={card} alt={pageContext.title}/> : "Loading"}
    </>
  );
}
export default AbruzzoNews;